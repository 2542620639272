import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Garage } from '@/models/dto/Garage'

const httpService: HttpService = new HttpService()

export default {
  getGarages(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Garage>>> {
    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      additionalQueries = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/garages?${query}`
    return httpService.get(url)
  },
  getGarage(garageId: number): Promise<AxiosResponse<Garage>> {
    const host = baseUrl()
    const url = `https://${host}/garages/${garageId}`
    return httpService.get(url)
  },
}
