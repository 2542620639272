/** DEPRECATED in favor of ReservationTrackingMapNew */
<template>
  <v-layout class="map-cont" position-relative>
    <v-layout row class="top-left__container">
      <slot name="top-left"></slot>
      <v-flex class="legend-item">
        <v-btn
          v-if="showLegend"
          v-show="isTrackingVisible"
          :id="`${id}-reservation-map-button-toggle-legend`"
          :class="`legend-btn__${toggleLegend ? 'on' : 'off'}`"
          solo
          flat
          @click="
            toggleLegend = !toggleLegend
            toggleEta = false
          "
        >
          Vehicles
        </v-btn>
        <v-flex
          v-show="toggleLegend"
          class="map-container map-container--legend"
        >
          <v-btn-toggle
            v-show="hasDriverAppData && hasELDData"
            v-model="selectedDataType"
            class="table-toggle elevation-0 mt-2"
            style="border-radius: 5px; z-index: 2"
          >
            <v-btn
              style="border-radius: 0px"
              class="data-button-toggle"
              flat
              value="ELD"
              @click="viewELDData"
            >
              ELD
            </v-btn>
            <v-btn
              style="border-radius: 0px"
              class="data-button-toggle"
              flat
              value="driverApp"
              @click="viewDriverAppData"
            >
              Driver App
            </v-btn>
          </v-btn-toggle>
          <v-list
            v-for="(journeysInGroup,
            tripVehicleGroupName,
            vi) in journeysByTripVehicleGroup"
            :key="`${vi}-tvg`"
          >
            <v-list-subheader v-if="tripVehicleGroupName">
              {{ tripVehicleGroupName }}
            </v-list-subheader>
            <v-list-item
              v-for="(item, mi) in journeysInGroup"
              :key="mi"
              style="display: flex; align-items: center"
            >
              <v-checkbox
                :disabled="!getCanViewVehicleMarker(item)"
                :value="highlightedVehicles.get(item.journeyId)"
                hide-details
                style="margin: 0; flex-grow: 0"
                @click.native="handleHighlightVehicle(item.journeyId)"
              />
              <v-list-tile-content class="legend-list-item">
                {{ item.vehicle.vehicleName }}
                <span
                  :class="`legend-list-box legend-list-box`"
                  :style="`background-color: ${getColorForJourney(
                    item.journeyId,
                    false
                  )};`"
                ></span>
              </v-list-tile-content>
              <v-switch
                v-show="vehiclesWithTrackingHistory[item.vehicle.vehicleId]"
                :key="mi"
                v-model="highlightedHistories"
                color="primary"
                :value="item.vehicle.vehicleId"
                style="display: flex; justify-content: flex-end"
                :inset="true"
                compact
                hide-details
              >
                <template #label>
                  <span
                    style="font-size: 14px"
                    :style="`color: ${
                      !vehiclesWithTrackingHistory[item.vehicle.vehicleId]
                        ? $cr.theme.grayMidLight
                        : ''
                    }`"
                  >
                    {{ vehicleEldTypes[item.vehicle.vehicleId] }}
                  </span>
                </template>
              </v-switch>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-flex>
      <v-flex class="legend-item">
        <v-btn
          v-if="showEtaBtn"
          v-show="isLiveVehicleLocationVisible"
          :id="`${id}-reservation-map-button-eta`"
          :class="`legend-btn__${toggleEta ? 'on' : 'off'}`"
          solo
          flat
          @click="
            toggleEta = !toggleEta
            toggleLegend = false
          "
        >
          ETA
        </v-btn>
        <v-list
          v-if="journeys"
          v-show="toggleEta"
          class="map-container map-container--legend"
        >
          <div
            v-for="(stop, idx) in stops"
            :key="idx"
            style="display: flex; align-items: center"
          >
            <v-list-tile-content class="legend-list-item">
              <span
                :class="`legend-list-box`"
                :style="`background-color: ${getColorForStop(stop)};`"
              ></span>
              Stop {{ idx + 1 }}
              <CalculateEtaButton
                :stop-id="stop.stopId"
                :vehicle-address="mapVehiclesToAddressAndJourneyStop(stop)"
                button-type="text"
                @eta="updateVehicleEtas"
              />
            </v-list-tile-content>
          </div>
        </v-list>
      </v-flex>
      <v-flex class="legend-item">
        <v-btn
          v-if="showLegend"
          v-show="
            isTrackingVisible &&
            !!Object.keys(vehiclesWithTrackingHistory).length
          "
          :id="`${id}-reservation-map-button-eta`"
          :class="`legend-btn__${isHistoryFiltering ? 'on' : 'off'}`"
          solo
          flat
          @click="toggleIsHistoryFiltering"
        >
          Filter History
        </v-btn>
      </v-flex>
    </v-layout>
    <div class="top-right__container">
      <v-btn v-if="!isLoading" icon color="white" @click="refreshWithUpdate">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <slot name="top-right" :isLoading="isLoading"></slot>
    </div>
    <div class="bottom-middle__container">
      <ReservationTrackingMapHistoryFilter
        v-if="isHistoryFiltering"
        v-model="historicalMarkersRange"
        :vehicle-time="vehicleTime"
        :historical-data="historicalData"
        :start-date="startDate"
        :end-date="endDate"
        :zone="firstTimezone"
        @update="filterHistoricalMarkersByRange"
        @update-vehicle-time="updateVehicleTime"
      />
    </div>

    <div
      v-if="isLoading"
      style="
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <v-progress-circular
        indeterminate
        :size="200"
        color="primary"
      ></v-progress-circular>
    </div>
    <GmapMap
      v-show="!isLoading"
      ref="gMap"
      :center="mapCenter"
      :options="{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyles,
      }"
      :zoom="defaultZoom"
      map-type-id="roadmap"
      style="width: 100%; height: 100%; overflow: hidden; border-radius: 10px"
    >
      <GmapInfoWindow :position="tooltipPosition" :opened="showTooltip">
        <div class="tooltip-content">
          The vehicle(s) were assigned mid trip.
          <br />
          This portion of the data has been added retroactively.
        </div>
      </GmapInfoWindow>
      <GmapPolyline
        v-for="(historicalMarker, z) in visibleHistoricalMarkers"
        :key="z"
        stroke-color="#5a5aff"
        :path="historicalMarker.path"
        :options="historicalMarker"
        @mouseover="enableTooltip($event, historicalMarker)"
        @mouseout="showTooltip = false"
      ></GmapPolyline>
      <GmapMarker
        v-for="(stopMarker, z) in visibleStopMarkers"
        :key="z"
        :position="stopMarker.position"
        :clickable="true"
        :icon="stopMarker.icon"
        @click="stopMarker.isWindowOpen = !stopMarker.isWindowOpen"
      >
        <GmapInfoWindow
          :position="stopMarker.position"
          :opened="stopMarker.isWindowOpen"
        >
          <StopInfoWindow :stop="stopMarker.stop" />
        </GmapInfoWindow>
      </GmapMarker>
      <GmapMarker
        v-for="(garageMarker, z) in visibleGarageMarkers"
        :key="z"
        :position="garageMarker.position"
        :clickable="true"
        :icon="garageMarker.icon"
        @click="garageMarker.isWindowOpen = !garageMarker.isWindowOpen"
      >
        <GmapInfoWindow
          :position="garageMarker.position"
          :opened="garageMarker.isWindowOpen"
        >
          <StopInfoWindow :stop="garageMarker.stop" is-garage />
        </GmapInfoWindow>
      </GmapMarker>
      <GmapMarker
        v-for="(vehicleMarker, z) in visibleVehicleMarkers"
        :key="z"
        :position="vehicleMarker.position"
        :clickable="true"
        :icon="vehicleMarker.icon"
        @mouseover="vehicleMarker.isBusWindowOpen = true"
        @mouseout="vehicleMarker.isBusWindowOpen = false"
      >
        <GmapInfoWindow
          :position="vehicleMarker.position"
          :opened="vehicleMarker.isBusWindowOpen"
        >
          <BusInfoWindow
            :reservation-id="vehicleMarker.reservationId"
            :vehicle="vehicleMarker.vehicle"
            :current-driver="vehicleMarker.driver"
            :company-name="vehicleMarker.companyName"
            :etas="vehicleMarker.etas"
          />
        </GmapInfoWindow>
      </GmapMarker>
    </GmapMap>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import { DateTime } from 'luxon'
import { gmapApi } from 'vue2-google-maps'
import GmapMarker from 'vue2-google-maps/dist/components/marker'
import GmapInfoWindow from 'vue2-google-maps/dist/components/infoWindow'
import { getTrackingHistoryV2 } from '@/services/reservations'
import { getTripAssignmentsForReservation } from '@/services/reservations'
import { authComputed } from '@/state/helpers'
import { mapStyles } from '@/components/mapStyles'
import BusIcon from '@/components/BusIcon.vue'
import StopIcon from '@/components/StopIcon.vue'
import BusInfoWindow from '@/components/BusInfoWindow.vue'
import StopInfoWindow from '@/components/StopInfoWindow.vue'
import CalculateEtaButton from '@/components/CalculateEtaButton.vue'
import ReservationTrackingMapHistoryFilter from '@/components/ReservationTrackingMapHistoryFilter.vue'
import {
  numMinutesBetweenDateTimes,
  numDaysBetweenDateTimes,
} from '@/utils/time'
import { deepClone } from '@/utils/deepClone'
import { ReservationStatus, ReservationTrackingAllocation } from '@/utils/enum'
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'

let BusIconClass = Vue.extend(BusIcon)
let StopIconClass = Vue.extend(StopIcon)

export default {
  components: {
    GmapInfoWindow,
    GmapMarker,
    BusInfoWindow,
    StopInfoWindow,
    CalculateEtaButton,
    ReservationTrackingMapHistoryFilter,
  },
  inject: ['isInReservationMapSidebar'],
  props: {
    showLegend: { type: Boolean, default: false },
    showEtaBtn: { type: Boolean, default: false },
    reservationId: { type: Number, default: () => null },
    reservationStatus: { type: String, default: 'Finished' },
    journeys: { type: Array, default: () => [] },
    startDate: { type: String, default: null },
    minRefresh: { type: Number, default: -300 },
    stops: { type: Array, required: true },
    drivingTime: { type: Number, default: null },
    tripVehicleGroups: { type: Array, default: () => [] },
    referredProviders: { type: Array, default: () => [] },
    fullTripVehicleGroups: { type: Array, default: () => [] },
  },
  data() {
    return {
      isHistoryFiltering: false,
      colors: [
        { name: 'blue', hex: '#3b9cf1' },
        { name: 'purple', hex: '#6a63e8' },
        { name: 'green', hex: '#7cd074' },
        { name: 'orange', hex: '#ea7721' },
      ],
      debounce: null,
      lastCalculated: null,
      toggleLegend: false,
      toggleEta: false,
      defaultZoom: 9,
      adjustedJourneys: [],
      isLoading: false,

      // history
      historicalData: [],
      historicalMarkers: [],
      highlightedHistories: [],
      historicalMarkersRange: [0, Infinity],
      vehicleTime: 0,
      isDriverAppDataOn: false,
      hasDriverAppData: false,
      hasELDData: false,
      trackingJourneyDataList: [],
      selectedDataType: null,
      showTooltip: false,
      tooltipPosition: { lat: 0, lng: 0 },
      historicalDataLoaded: false,

      // vehicles
      vehicleMarkers: [],
      highlightedVehicles: new Map(),
      liveTrackers: [],
      trackingSources: [],
      trackingAllocation: null,
      trackingReservationStatus: null,
      vehiclesWithTrackingHistory: {},
      vehicleEldTypes: {},

      // directions
      directionDisplays: [],

      // stops
      stopMarkers: [],

      // garage
      garages: [],
      garageMarkers: [],

      // map
      map: null,
      mapCenter: { lat: 35.5, lng: -98.35 },
      bounds: null,
      mapStyles: [
        ...mapStyles,
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }
  },
  computed: {
    google: gmapApi,
    ...authComputed,
    ...mapGetters({
      distanceAndDuration: 'trackingDevices/distanceAndDuration',
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
    journeysByTripVehicleGroup() {
      let journeysByTripVehicleGroup = {}

      for (let journey of this.adjustedJourneys) {
        let id = journey.vehicle.tripVehicleGroupId
        let name = this.tripVehicleGroups.find(
          (tvg) => tvg.tripVehicleGroupId === id
        )?.name

        if (!name) {
          name = ''
        }

        if (!journeysByTripVehicleGroup[name]) {
          journeysByTripVehicleGroup[name] = []
        }

        journeysByTripVehicleGroup[name].push(journey)
      }
      return journeysByTripVehicleGroup
    },
    endDate() {
      return this.stops
        .flatMap((s) => [s.pickupDatetime, s.dropoffDatetime])
        .filter((t) => !!t)
        .sort((a, b) => DateTime.fromISO(a) > DateTime.fromISO(b))
        .pop()
    },
    stale() {
      return (
        !this.lastCalculated ||
        this.lastCalculated.diffNow('seconds').seconds < this.minRefresh
      )
    },
    allJourneyStops() {
      return this.journeys.reduce((allStops, journey) => {
        if (journey.journeyStops.length > 0) {
          allStops.push(
            ...journey.journeyStops.map((journeyStop) => {
              return { ...journeyStop.stop, reached: journeyStop.reached }
            })
          )
        }
        return allStops
      }, [])
    },
    isLiveVehicleLocationVisible() {
      return (
        (this.reservationStatus === 'started' ||
          this.reservationStatus === 'upcoming') &&
        this.reservationStartsWithinAnHour
      )
    },
    isTrackingVisible() {
      return (
        ((this.reservationStatus === 'started' ||
          this.reservationStatus === 'upcoming') &&
          this.reservationStartsWithinAnHour) ||
        this.reservationStatus === 'finished'
      )
    },
    firstTimezone() {
      return this.stops.filter((stop) => stop?.address?.timeZone)[0]?.address
        ?.timeZone
    },
    reservationStartsWithinAnHour() {
      if (this.startDate === null) {
        return false
      }
      const firstTimezone = this.stops.filter(
        (stop) => stop?.address?.timeZone
      )[0]?.address?.timeZone
      let startDate
      if (firstTimezone) {
        startDate = DateTime.fromISO(this.startDate, {
          zone: firstTimezone,
        }).toJSDate()
      } else {
        startDate = DateTime.fromISO(this.startDate).toJSDate()
      }
      const today = DateTime.fromJSDate(new Date())
        .setZone(this.currentUser.timeZone)
        .toJSDate()
      return numMinutesBetweenDateTimes(today, startDate) <= 60
    },
    shouldShowTrackingTag() {
      if (this.startDate === null) {
        return null
      }
      const filteredStops = this.stops.filter((stop) => stop?.address?.timeZone)

      const firstTimezone = filteredStops[0]?.address?.timeZone
      let startDate
      if (firstTimezone) {
        startDate = DateTime.fromISO(this.startDate, {
          zone: firstTimezone,
        }).toJSDate()
      } else {
        startDate = DateTime.fromISO(this.startDate).toJSDate()
      }

      const now = DateTime.fromJSDate(new Date())
        .setZone(this.currentUser.timeZone)
        .toJSDate()

      if (numDaysBetweenDateTimes(now, startDate) > 7) {
        return false
      }
      return true
    },
    computedTrackingReservationStatus() {
      if (this.startDate === null) {
        return null
      }
      const filteredStops = this.stops.filter((stop) => stop?.address?.timeZone)
      const firstTimezone = filteredStops[0]?.address?.timeZone
      let startDate
      if (firstTimezone) {
        startDate = DateTime.fromISO(this.startDate, {
          zone: firstTimezone,
        }).toJSDate()
      } else {
        startDate = DateTime.fromISO(this.startDate).toJSDate()
      }
      const lastTimezone =
        filteredStops[filteredStops.length - 1]?.address?.timeZone
      let endDate
      if (lastTimezone) {
        endDate = DateTime.fromISO(this.endDate, {
          zone: lastTimezone,
        }).toJSDate()
      } else {
        endDate = DateTime.fromISO(this.endDate).toJSDate()
      }
      const now = DateTime.fromJSDate(new Date())
        .setZone(this.currentUser.timeZone)
        .toJSDate()
      if (
        numDaysBetweenDateTimes(now, startDate) < 7 &&
        numMinutesBetweenDateTimes(now, startDate) > 60
      ) {
        return ReservationStatus.Upcoming
      }
      if (
        numMinutesBetweenDateTimes(now, startDate) <= 60 &&
        numMinutesBetweenDateTimes(endDate, now) <= 60
      ) {
        return ReservationStatus.Started
      }
      if (numMinutesBetweenDateTimes(endDate, now) > 60) {
        return ReservationStatus.Finished
      }
      return null
    },
    visibleStopMarkers() {
      return this.stopMarkers.filter((marker) => marker.isVisible)
    },
    visibleGarageMarkers() {
      return this.garageMarkers.filter((marker) => marker.isVisible)
    },
    visibleVehicleMarkers() {
      return this.vehicleMarkers.filter((v) => v.isVisible)
    },
    visibleHistoricalMarkers() {
      return this.historicalMarkers.filter(
        (obj) =>
          obj.isVisible || this.highlightedHistories.includes(obj.vehicleId)
      )
    },
    vehicleIds() {
      return this.journeys.map((journey) => {
        if (journey?.vehicle?.vehicleId) {
          return journey.vehicle.vehicleId
        }
      })
    },
  },
  watch: {
    journeys: {
      deep: true,
      async handler() {
        await this.loadJourneyData()
        this.loadMap()
      },
    },
  },
  async mounted() {
    this.isLoading = true
    this.currentProvider = this.referredProviders[0]
    await this.loadJourneyData()
    if (this.isInReservationMapSidebar) {
      await this.loadMap()
    }
    this.isLoading = false
    EventBus.$on('update-sync-time', (vehicleIds) => {
      vehicleIds.forEach((id) => {
        const tracker = this.liveTrackers.find((d) => d.vehicleId === id)
        if (tracker?.lastReportTime) {
          const vehicleMarker = this.vehicleMarkers.find((marker) => {
            return marker.vehicle.vehicleId == id
          })
          vehicleMarker.vehicle.lastSyncTime = tracker.lastReportTime
        }
      })
    })
  },
  methods: {
    ...mapActions({
      getTrackingDevicesByVehicleIdsV2:
        'trackingDevices/getTrackingDevicesByVehicleIdsV2',
      getGaragesByVehicleIds: 'vehicles/getGaragesByVehicleIds',
    }),
    enableTooltip(event, historicalMarker) {
      if (historicalMarker.showTooltip) {
        this.showTooltip = true
        this.updateTooltipPosition(event)
      }
    },
    updateTooltipPosition(event) {
      this.tooltipPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
    },
    viewDriverAppData() {
      this.isDriverAppDataOn = true
      this.refreshWithUpdate()
    },
    viewELDData() {
      this.isDriverAppDataOn = false
      this.refreshWithUpdate()
    },
    getCanViewVehicleMarker(item) {
      const marker = this.vehicleMarkers.find(
        (vm) => vm.journeyId === item.journeyId
      )
      if (this.reservationStatus === 'finished') {
        return marker && this.isHistoryFiltering
      } else {
        return this.vehicleMarkers.find((vm) => vm.journeyId === item.journeyId)
      }
    },
    toggleIsHistoryFiltering() {
      this.isHistoryFiltering = !this.isHistoryFiltering

      // clear bus icons when closing
      if (this.reservationStatus === 'finished') {
        if (!this.isHistoryFiltering) {
          this.vehicleMarkers.forEach((vehicleMarker) => {
            vehicleMarker.isVisible = false
          })
          this.highlightedVehicles.forEach((value, key) => {
            this.highlightedVehicles.set(key, false)
          })
        } else {
          this.vehicleMarkers.forEach((vehicleMarker) => {
            vehicleMarker.isVisible = true
          })
        }
      }
    },
    updateVehicleEtas(openBusWindow = true) {
      this.vehicleMarkers.forEach((vehicleMarker) => {
        vehicleMarker.etas = []
        this.stops.forEach((stop) => {
          let estimate = this.distanceAndDuration(
            vehicleMarker.vehicle.vehicleId,
            stop.address.addressId
          )
          if (estimate) {
            const { arrivalTime, duration, timeZone } = estimate.stop
            vehicleMarker.etas.push({
              stopNumber: stop.orderIndex + 1,
              arrivalTime: DateTime.fromISO(arrivalTime, { zone: timeZone }),
              duration,
              timeZone,
            })
          }
        })
        vehicleMarker.isBusWindowOpen = openBusWindow
      })
    },
    async loadMap() {
      if (this.$refs.gMap == null) {
        return
      }

      const map = await this.$refs.gMap.$mapPromise
      this.map = map
      this.bounds = new this.google.maps.LatLngBounds()
      await this.makeMarkers()
      this.map.fitBounds(this.bounds)
      this.lastCalculated = DateTime.local()
    },
    async loadJourneyData() {
      let { data } = await getTripAssignmentsForReservation({
        reservationIds: [this.reservationId],
      })
      const vehicleAssignments = data?.vehicleAssignments
      this.adjustedJourneys = deepClone(this.journeys)
      for (const journey of this.adjustedJourneys) {
        journey.stops.forEach((journeyStop) => {
          journeyStop.address = this.stops.find(
            (stop) => stop.stopId === journeyStop.stopId
          )?.address
        })
        journey.driver = vehicleAssignments.find(
          (va) => va.vehicleId === journey.vehicle.vehicleId
        )?.driverAssignments?.[0]?.driver

        journey.vehicle.tripVehicleGroupId = vehicleAssignments.find(
          (va) => va.vehicleId === journey.vehicle.vehicleId
        )?.tripVehicleGroupId

        journey.vehicle.assignedDateTime = vehicleAssignments.find(
          (va) => va.vehicleId === journey.vehicle.vehicleId
        )?.createdOn

        this.highlightedVehicles.set(journey.journeyId, false)
      }
      if (
        this.isLiveVehicleLocationVisible ||
        this.trackingReservationStatus === ReservationStatus.Upcoming ||
        this.trackingReservationStatus === ReservationStatus.Started
      ) {
        const resp = await this.getTrackingDevicesByVehicleIdsV2({
          requestBody: {
            vehicleIds: this.vehicleIds,
          },
          prioritizeEld: true,
        })
        const trackers = resp.data.devices
        this.trackingSources = trackers
        if (this.isLiveVehicleLocationVisible) {
          this.liveTrackers = trackers.filter((tracker) => {
            if (!tracker?.lastReportTime) {
              return false
            }
            const firstTimezone = this.stops.filter(
              (stop) => stop?.address?.timeZone
            )[0]?.address?.timeZone
            let startDate
            let trackingTime
            if (firstTimezone) {
              startDate = DateTime.fromISO(this.startDate, {
                zone: firstTimezone,
              }).toJSDate()
              trackingTime = DateTime.fromISO(tracker.lastReportTime)
                .setZone(firstTimezone)
                .toJSDate()
            } else {
              startDate = DateTime.fromISO(this.startDate).toJSDate()
              trackingTime = DateTime.fromISO(tracker.lastReportTime).toJSDate()
            }
            return (
              tracker?.lastReportTime &&
              numMinutesBetweenDateTimes(trackingTime, startDate) <= 60
            )
          })
        }
      } else if (this.reservationStatus === 'finished') {
        this.highlightedHistories = [...new Set(this.vehicleIds)]
      }
      const res = await this.getGaragesByVehicleIds(this.vehicleIds)
      this.garages = res.data.garages.map((garage) => {
        garage.address = garage.addressDTO
        delete garage.addressDTO
        return { ...garage }
      })
    },
    async makeMarkers() {
      // Only show garages/directions/vehicles if started or upcoming within an hour
      if (this.isLiveVehicleLocationVisible) {
        this.makeGarageMarkers(true)
        this.makeDirectionsMarkers(true)
      }
      for (const journey of this.adjustedJourneys) {
        this.makeVehicleMarkers(journey)
        // Load all vehicle history but only default shown if finished
        if (this.isTrackingVisible) {
          await this.setHistoricalData(journey)
          this.makeHistoricalMarkers(journey)
        }
      }
      const bounds = new this.google.maps.LatLngBounds()
      for (const historicalMarker of this.historicalMarkers) {
        for (const pos of historicalMarker.path) {
          bounds.extend(new this.google.maps.LatLng(pos.lat, pos.lng))
        }
      }
      this.bounds.union(bounds)
      this.historicalDataLoaded = true
      this.makeStopMarkers()
    },
    async handleHighlightVehicle(journeyId) {
      const newValue = !this.highlightedVehicles.get(journeyId)
      await this.highlightedVehicles.set(journeyId, newValue)

      this.highlightVehicles()
    },
    highlightVehicles() {
      const highlightedVehicleKeys = [...this.highlightedVehicles.entries()]
        .filter(([k, v]) => v)
        .map(([k, v]) => k)
      if (
        !highlightedVehicleKeys.length ||
        highlightedVehicleKeys.length === this.journeys.length
      ) {
        for (const marker of this.vehicleMarkers) {
          marker.isVisible = true
        }
        return
      }
      for (const marker of this.vehicleMarkers) {
        marker.isVisible = highlightedVehicleKeys.includes(marker.journeyId)
      }
    },
    getColorForJourney(id, returnName = true) {
      let journeyIndex = this.adjustedJourneys
        .map((journey) => journey.journeyId)
        .indexOf(id)
      if (journeyIndex < 0) {
        journeyIndex = 0
      }
      return returnName
        ? this.colors[journeyIndex % 4].name
        : this.colors[journeyIndex % 4].hex
    },
    getColorForStop(stop) {
      if (stop.reached) {
        return this.$cr.theme.green
      } else if (
        !stop.reached &&
        (stop.orderIndex === 0 || this.stops[stop.orderIndex - 1].reached) &&
        (this.trackingAllocation === ReservationTrackingAllocation.Partial ||
          this.trackingAllocation === ReservationTrackingAllocation.All) &&
        this.reservationStatus === 'started'
      ) {
        return this.$cr.theme.blue
      } else {
        return this.$cr.theme.grayDark
      }
    },
    async setHistoricalData(journey) {
      const { reservationId, journeyId, vehicle } = journey
      const vehicleId = vehicle.vehicleId
      let payload = { reservationId, idsList: [{ journeyId, vehicleId }] }
      if (!this.historicalDataLoaded) {
        const resp = await getTrackingHistoryV2(payload)
        if (!resp.data.trackingJourneyDataList) {
          return
        }
        this.trackingJourneyDataList[journeyId] =
          resp.data.trackingJourneyDataList

        this.hasDriverAppData = resp.data.trackingJourneyDataList.some(
          (tj) => tj.eldType === 'COACHRAIL' && tj.gpsData.length > 0
        )
        this.hasELDData = resp.data.trackingJourneyDataList.some(
          (tj) => tj.eldType !== 'COACHRAIL' && tj.gpsData.length > 0
        )
        if (!this.hasELDData && this.hasDriverAppData) {
          this.selectedDataType = 'driverApp'
          this.isDriverAppDataOn = true
        } else {
          this.selectedDataType = 'ELD'
        }
      }
      let trackingJourneyDataList
      if (this.isDriverAppDataOn) {
        trackingJourneyDataList = this.trackingJourneyDataList[
          journeyId
        ].filter((tj) => tj.eldType === 'COACHRAIL' && tj.gpsData.length > 0)
      } else {
        trackingJourneyDataList = this.trackingJourneyDataList[
          journeyId
        ].filter((tj) => tj.eldType !== 'COACHRAIL' && tj.gpsData.length > 0)
      }
      for (const trackingJourney of trackingJourneyDataList) {
        if (trackingJourney.gpsData) {
          if (trackingJourney.eldType === 'COACHRAIL') {
            this.vehicleEldTypes[vehicleId] = 'Driver App'
          } else {
            this.vehicleEldTypes[
              vehicleId
            ] = `${trackingJourney.eldType?.charAt(
              0
            )}${trackingJourney.eldType?.slice(1)?.toLowerCase()}`
          }
          const length = trackingJourney.gpsData.length
          if (
            !this.vehiclesWithTrackingHistory[vehicleId] ||
            this.vehiclesWithTrackingHistory[vehicleId] < length
          ) {
            this.vehiclesWithTrackingHistory[vehicleId] = length
          }
          if (length > 500) {
            const skipCount = Math.round(length / 500)
            const reducedGpsData = []
            for (let index = 0; index < length; index = index + skipCount) {
              reducedGpsData.push(trackingJourney.gpsData[index])
            }
            trackingJourney.gpsData = reducedGpsData
          }
          trackingJourney.gpsData = trackingJourney.gpsData.map((gps) => {
            return { ...gps, journeyId }
          })
          this.historicalData.push(...trackingJourney.gpsData)
        }
      }
    },
    refreshWithUpdate() {
      if (this.debounce) {
        window.clearTimeout(this.debounce)
      }
      this.debounce = window.setTimeout(() => {
        this.clearMap()
        this.loadMap()
      }, 500)
    },
    clearMap() {
      if (this.stale) {
        for (const display of this.directionDisplays) {
          display.setMap(null)
        }
        this.liveTrackers = []
        this.trackingAllocation = null
      }
      this.historicalMarkers = []
      this.vehicleMarkers = []
      this.directionDisplays = []
      this.stopMarkers = []
      this.garageMarkers = []
      this.historicalData = []
    },
    sortJourney(a, b) {
      return (a.receivedDate || a.reportedOn) > (b.receivedDate || b.reportedOn)
        ? 0
        : -1
    },
    makeStopMarkers() {
      if (!this.stops?.length) {
        return
      }
      let lastStopReached = this.isLiveVehicleLocationVisible
      // Aggregate stops by address lat/lng
      const aggregatedStops = this.stops.reduce((newList, stop) => {
        const {
          address: { lat, lng },
        } = stop

        let matchingStop = newList.find(
          (s) => s.address.lat === lat && s.address.lng === lng
        )

        if (!matchingStop) {
          // Create a list for each of the necessary information to be shown on stop info window
          matchingStop = {
            ...stop,
            repeatedStop: false,
            reachedList: [],
            orderIndexList: [],
            pickupDatetimeList: [],
            dropoffDatetimeList: [],
          }

          newList.push(matchingStop)
        } else {
          matchingStop.repeatedStop = true
        }

        const matchingJourneyStops = this.allJourneyStops.filter(
          (ajs) => ajs.stopId === stop.stopId
        )

        matchingStop.reachedList.push(
          !!matchingJourneyStops.length &&
            matchingJourneyStops.every((ajs) => ajs.reached)
        )

        matchingStop.orderIndexList.push(stop.orderIndex)
        if (stop?.pickupDatetime) {
          matchingStop.pickupDatetimeList.push(stop.pickupDatetime)
        }
        if (stop?.dropoffDatetime) {
          matchingStop.dropoffDatetimeList.push(stop.dropoffDatetime)
        }
        return newList
      }, [])
      aggregatedStops.forEach((stop) => {
        const {
          address: { lat, lng },
        } = stop
        this.bounds.extend({
          lat,
          lng,
        })

        let propsData = {
          number: Math.min(...stop.orderIndexList) + 1,
          repeatedStop: stop.repeatedStop,
        }
        // Every stop in aggregation must be reached to be completed
        const reached = !!stop.reachedList.every((reached) => reached)
        if (reached) {
          propsData.status = 'COMPLETED'
          stop.status = 'COMPLETED'
        } else if (lastStopReached) {
          propsData.status = 'EN ROUTE'
          stop.status = 'EN ROUTE'
        } else {
          propsData.status = 'TO DO'
          stop.status = 'TO DO'
        }
        let component = new StopIconClass({ propsData })
        component.$mount()

        const marker = {
          icon: {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
          },
          scaledSize: new google.maps.Size([35, 40]),
          position: new this.google.maps.LatLng(lat, lng),
          reservationId: this.reservationId,
          stop: stop,
          isVisible: true,
          isWindowOpen: false,
        }

        this.stopMarkers.push(marker)

        lastStopReached = reached
      })
    },
    makeGarageMarkers() {
      this.garages.forEach((garage) => {
        const {
          address: { lat, lng },
        } = garage

        let propsData = {
          status: 'GARAGE',
        }

        let component = new StopIconClass({ propsData })
        component.$mount()

        const marker = {
          icon: {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
          },
          scaledSize: new google.maps.Size([35, 40]),
          position: new this.google.maps.LatLng(lat, lng),
          reservationId: this.reservationId,
          stop: garage,
          isVisible: true,
          isWindowOpen: false,
        }

        this.garageMarkers.push(marker)
      })
    },
    makeVehicleMarkers(journey) {
      const reservationId = this.reservationId
      let { journeyId, vehicle, driver } = journey
      let companyName = vehicle?.companyName
      let tracker = this.liveTrackers.find(
        (d) => d.vehicleId === vehicle.vehicleId
      )
      const lastKnown = tracker
      const previousKnown = tracker

      if (lastKnown && previousKnown) {
        const pointA = new this.google.maps.LatLng(
          previousKnown.lat,
          previousKnown.lng
        )
        const pointB = new this.google.maps.LatLng(lastKnown.lat, lastKnown.lng)

        let heading = this.google.maps.geometry.spherical.computeHeading(
          pointA,
          pointB
        )
        this.bounds.extend(pointA)
        this.bounds.extend(pointB)

        let propsData = {
          color: this.getColorForJourney(journeyId, false),
          heading,
        }
        let component = new BusIconClass({ propsData })
        component.$mount()

        const icon = {
          url:
            'data:image/svg+xml,' + encodeURIComponent(component.$el.outerHTML),
          scaledSize: new this.google.maps.Size(60, 60),
          anchor: new this.google.maps.Point(30, 30),
        }

        if (!vehicle) {
          vehicle = {}
        }

        if (tracker?.lastReportTime) {
          vehicle.lastSyncTime = tracker.lastReportTime
        }

        const marker = {
          journeyId,
          isBusWindowOpen: false,
          isVisible: true,
          icon,
          position: new this.google.maps.LatLng(lastKnown.lat, lastKnown.lng),
          reservationId,
          vehicle,
          driver,
          companyName,
          etas: [],
        }
        this.vehicleMarkers.push(marker)
      }
      this.updateVehicleEtas(false)
    },
    makeDirectionsMarkers(updateBounds = true) {
      const bounds = new this.google.maps.LatLngBounds()

      const directionsService = (() => {
        const ds = new this.google.maps.DirectionsService()
        let counter = 1
        return (directionsServiceOptions, callbackFn) => {
          counter++
          setTimeout(() => {
            ds.route(directionsServiceOptions, callbackFn)
            counter--
          }, 80 * counter)
        }
      })()

      if (!this.adjustedJourneys.length) {
        return
      }

      const plotlineData = this.adjustedJourneys?.[
        this.adjustedJourneys.length - 1
      ].stops.map((s) => ({
        location: {
          lat: s?.address?.lat,
          lng: s?.address?.lng,
        },
      }))

      const lineSymbol = {
        path: this.google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        scale: 3,
      }
      const polylineOptions = {
        strokeColor: this.$cr.theme.grayMidLight,
        strokeOpacity: 0,
        fillOpacity: 1,
        icons: [
          {
            icon: lineSymbol,
            offset: '0',
            repeat: '10px',
          },
        ],
      }
      const directionsDisplay = new this.google.maps.DirectionsRenderer({
        polylineOptions,
        preserveViewport: true,
        suppressMarkers: true,
      })
      this.directionDisplays.push(directionsDisplay)
      const directionsComplete = (directionsData, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(directionsData)

          if (updateBounds) {
            this.map.fitBounds(bounds.union(directionsData.routes[0].bounds))
          }
        }
      }
      const directionsOptions = {
        waypoints: plotlineData,
        origin: plotlineData[0],
        destination: plotlineData[plotlineData.length - 1],
        travelMode: 'DRIVING',
      }

      if (this.stale) {
        directionsService(directionsOptions, directionsComplete)
      }
    },
    updateVehicleTime(newVehicleTime) {
      this.newVehicleTime = newVehicleTime
      const reservationId = this.reservationId
      for (const journey of this.adjustedJourneys) {
        let { journeyId, vehicle, driver } = journey
        let companyName = vehicle?.companyName
        let x = this.historicalData.filter(
          (hd) => hd.journeyId === journey.journeyId
        )

        x = x.sort((a, b) => {
          return (
            Math.abs(
              DateTime.fromISO(a.receivedDate || a.reportedOn).toSeconds() -
                newVehicleTime
            ) -
            Math.abs(
              DateTime.fromISO(b.receivedDate || b.reportedOn).toSeconds() -
                newVehicleTime
            )
          )
        })

        x = x[0]

        const lastKnown = x
        const previousKnown = x

        if (lastKnown && previousKnown) {
          const pointA = new this.google.maps.LatLng(
            previousKnown.lat,
            previousKnown.lng
          )
          const pointB = new this.google.maps.LatLng(
            lastKnown.lat,
            lastKnown.lng
          )

          let heading = this.google.maps.geometry.spherical.computeHeading(
            pointA,
            pointB
          )
          this.bounds.extend(pointA)
          this.bounds.extend(pointB)

          let propsData = {
            color: this.getColorForJourney(journeyId, false),
            heading,
          }
          let component = new BusIconClass({ propsData })
          component.$mount()

          const icon = {
            url:
              'data:image/svg+xml,' +
              encodeURIComponent(component.$el.outerHTML),
            scaledSize: new this.google.maps.Size(60, 60),
            anchor: new this.google.maps.Point(30, 30),
          }

          if (!vehicle) {
            vehicle = {}
          }

          let marker = this.vehicleMarkers.find(
            (marker) => marker.journeyId === journey.journeyId
          )

          if (!marker) {
            marker = {
              journeyId,
              isBusWindowOpen: false,
              isVisible: true,
              icon,
              reservationId,
              vehicle,
              driver,
              companyName,
              etas: [],
            }
            this.vehicleMarkers.push(marker)
          }
          let state = marker.isVisible
          marker.isVisible = false
          marker.vehicle.lastSyncTime =
            lastKnown.receivedDate || lastKnown.reportedOn
          marker.position = new this.google.maps.LatLng(
            lastKnown.lat,
            lastKnown.lng
          )
          marker.isVisible = state
        }
      }
    },
    filterHistoricalMarkersByRange(newHistoricalMarkersRange) {
      this.historicalMarkersRange = newHistoricalMarkersRange
      if (this.vehicleTime < this.historicalMarkersRange[0]) {
        this.vehicleTime = this.historicalMarkersRange[0]
      }
      if (this.vehicleTime > this.historicalMarkersRange[1]) {
        this.vehicleTime = this.historicalMarkersRange[1]
      }
      this.historicalMarkers = []
      for (const journey of this.adjustedJourneys) {
        this.makeHistoricalMarkers(journey)
      }
      this.updateVehicleTime(this.vehicleTime)
    },
    makeHistoricalMarkers(itemToPlot) {
      if (!itemToPlot) {
        return
      }

      const gpsData = this.historicalData.filter((hd) => {
        return (
          hd.journeyId === itemToPlot.journeyId &&
          DateTime.fromISO(hd.receivedDate || hd.reportedOn).toSeconds() >
            this.historicalMarkersRange[0] &&
          DateTime.fromISO(hd.receivedDate || hd.reportedOn).toSeconds() <
            this.historicalMarkersRange[1]
        )
      })
      gpsData.sort(this.sortJourney)
      gpsData.reverse()

      const preAssignmentGpsData = gpsData.filter(
        (gps) =>
          new Date(gps.reportedOn) <
          new Date(itemToPlot?.vehicle?.assignedDateTime)
      )
      const postAssignmentGpsData = gpsData.filter(
        (gps) =>
          new Date(gps.reportedOn) >=
          new Date(itemToPlot?.vehicle?.assignedDateTime)
      )
      this.historicalMarkers.push({
        vehicleId: itemToPlot?.vehicle?.vehicleId,
        journeyId: itemToPlot.journeyId,
        isVisible: false,
        path: preAssignmentGpsData.map((pos) => ({
          lat: Number(pos.lat),
          lng: Number(pos.lng),
        })),
        strokeColor: this.getColorForJourney(itemToPlot.journeyId),
        strokeOpacity: 0.25,
        strokeWidth: 3,
        strokeWeight: 3,
        showTooltip: true,
      })
      this.historicalMarkers.push({
        vehicleId: itemToPlot?.vehicle?.vehicleId,
        journeyId: itemToPlot.journeyId,
        isVisible: false,
        path: postAssignmentGpsData.map((pos) => ({
          lat: Number(pos.lat),
          lng: Number(pos.lng),
        })),
        strokeColor: this.getColorForJourney(itemToPlot.journeyId),
        strokeOpacity: 0.5,
        strokeWidth: 3,
        strokeWeight: 3,
        showTooltip: false,
      })
    },
    getNextStop(vehicleId) {
      const { journeyStops } = this.adjustedJourneys.find(
        (j) => j.vehicle.vehicleId === vehicleId
      )
      const activeStops = journeyStops.reduce((activeStops, journeyStop) => {
        if (!journeyStop.reached) {
          activeStops.push(journeyStop.stop)
        }
        return activeStops
      }, [])
      if (activeStops.length === 0) {
        return null
      }
      const nextStop = activeStops[0]
      return nextStop
    },
    mapVehiclesToAddressAndJourneyStop: function (stop) {
      const vehicleAddresses = new Map()
      this.adjustedJourneys.forEach((journey) => {
        const { vehicle } = journey
        journey.journeyStops.find((journeyStop) => {
          if (journeyStop.stop.stopId === stop.stopId) {
            vehicleAddresses.set(vehicle.vehicleId, {
              address: stop.address,
              journeyStopId: journeyStop.journeyStopId,
            })
          }
        })
      })
      return vehicleAddresses
    },
  },
}
</script>

<style lang="scss" scoped>
.map-container {
  padding: 10px 15px;
  font-size: 14px;
  background-color: white;
  border: 1em;
  border-radius: 10px;
  box-shadow: 0px 3px 4px rgba($black-base, 0.18);

  .v-input {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }

  &--journeys {
    width: 400px;
    margin-left: 243px;
  }

  &--timezones {
    width: 310px;
    margin-left: 374px;
  }

  &--legend {
    margin-left: 6px;
    position: fixed;
  }
}

.v-list__tile__action {
  margin-top: 0px;
}

.journey-checkbox {
  &--blue {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $primary !important;
      caret-color: $primary !important;
    }
  }

  &--purple {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $purple !important;
      caret-color: $purple !important;
    }
  }

  &--green {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $green !important;
      caret-color: $green !important;
    }
  }

  &--orange {
    ::v-deep .accent--text,
    ::v-deep .v-icon {
      color: $orange !important;
      caret-color: $orange !important;
    }
  }
}

.legend-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;

  p {
    margin: 0 0 0 5px;
  }

  .legend-list-box {
    width: 13px !important;
    height: 13px !important;
    margin: 0 8px;
    flex-shrink: 0;
    border-radius: 50%;
  }
}

.legend-cont {
  position: absolute;
  left: 50px;
  z-index: 9999;
}

.top-left {
  &__container {
    position: absolute;
    padding-left: 10px;
    padding-top: 10px;
    z-index: 1;
  }
}

.top-right {
  &__container {
    padding-top: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    z-index: 1;
    .v-btn--icon {
      &::before {
        border-radius: unset !important;
      }
    }
  }
}

.bottom-middle {
  &__container {
    z-index: 1;
    position: absolute;
    left: 10%;
    right: 15%;
    bottom: 2%;
    margin: 0 auto;
  }
}

.refresh-btn {
  margin-top: 10px;
  margin-left: 10px;
  color: $white;
  background-color: $primary;
}

.map-cont {
  width: 100%;
  height: inherit;
}

@media (max-width: 599px), (max-height: 599px) {
  .legend-btn__on .legend-btn__off {
    display: none;
  }
}
.legend-item {
  margin-top: 10px;
}
.legend-btn {
  &__on {
    color: $white;
    background-color: $blue-dark;
  }
  &__off {
    color: $white;
    background-color: $blue;
  }
}
.data-button-toggle {
  border: 1px solid $gray-light !important;
  padding: 5px 10px;
  font-size: 12px;
}

.tooltip-content {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

::v-deep .v-input--switch__track.primary--text {
  color: $blue-dull !important;
}
::v-deep .v-input--switch__thumb {
  height: 9px !important;
  width: 9px !important;
  top: calc(50% - 5px) !important;
  right: -5px;
}
::v-deep .v-input--switch__thumb.primary--text {
  color: $primary !important;
  right: -8px !important;
}
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;
}
::v-deep {
  .table-toggle {
    .v-btn:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    .v-btn:last-child {
      border-radius: 0 5px 5px 0 !important;
    }

    .v-btn.v-btn--active {
      background-color: $blue-dark;
    }
  }
}
</style>
